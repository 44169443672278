import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Grid,
    Stack,
    styled,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useIsMobileHook, weaponImageName } from "../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { allWeapons } from "../builder/WeaponData";
import MetaLoadouts from "../builder/share/MetaLoadouts";
import { Add, ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import xdiLogo from "../assets/partners/xdi.png";
import React from "react";
import MobileAd, { MobileSlots } from "../components/ads/MobileAd";
import { Namespaces } from "../config/localisation/Localisation";
import { useTranslation } from "react-i18next";
import { AdSizes } from "../components/ads/NitroAds";

export const ranking = {
    'casual': [
        {
            name: "S Tier",
            color: "#FF7F7F",
            weapons: ["MDR", "MP7", "L115", "PP-19", "Vector .45", "LVOA-C^", "M16A4^"]
        },
        {
            name: "A Tier",
            color: "#FFBF7F",
            weapons: ["ACR", "M4A1^", "L86", "M60", "M44", "MK 20 SSR", "M249", "TAC-50", "P90", "AK-47%"]
        },
        {
            name: "B Tier",
            color: "#FFDF7F",
            weapons: ["AA-12", "MP5A2", "SVD", "M870", "SPAS-12"]
        },
        {
            name: "C Tier",
            color: "#FFFF7F",
            weapons: ["RPK-74", "Double-Barreled"]
        },
        // {
        //     name: "F Tier",
        //     color: "#BFFF7F",
        //     weapons: []
        // }
    ],
    'ranked': [
        {
            name: "S Tier",
            color: "#FF7F7F",
            weapons: ["ACR", "MP7", "MDR^", "M16A4", "LVOA-C^", "Vector .45"]
        },
        {
            name: "A Tier",
            color: "#FFBF7F",
            weapons: ["TAC-50", "MK 20 SSR", "M249", "M60", "AK-47%", "PP-19%"],
        },
        {
            name: "B Tier",
            color: "#FFDF7F",
            weapons: ["P90", "L115", "SVD"]
        },
        {
            name: "C Tier",
            color: "#FFFF7F",
            weapons: ["MP5A2", "Double-Barreled", "M44", "M4A1"],
            expanded: false
        },
        {
            name: "F Tier",
            color: "#BFFF7F",
            weapons: ["AA-12", "M870", "RPK-74"],
            expanded: false
        }
    ]
}

function weaponImage(weapon) {
    return <div
        style={{
            backgroundImage: `url(${require('../assets/weapons/outline/' + weaponImageName(weapon) + '.png')})`,
            height: '50px',
            width: '95%',
            margin: '5px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
        }}
        aria-description={weapon}
    />;
}

const MetaWeaponItem = ({ weapon, category }) => {
    const { i18n, t } = useTranslation(Namespaces.weapons)

    function weaponName(weapon) {
        return weapon.name === "Double-Barreled" ? t("doubleBarreledShort") : t(weapon.name)
    }

    const shortWeaponType = (weapon) => {
        const shortened = {
            en: {
                "Assault Rifle": "arShort",
                "Sniper Rifle": "sniperShort",
                "Marksman Rifle": "dmrShort"
            },
            es: {
                "LMG": "lmgShort",
                "Sniper Rifle": "sniperShort",
                "Assault Rifle": "arShort",
                "Marksman Rifle": "dmrShort"
            }
        }
        return shortened[i18n.resolvedLanguage][weapon.type] ? t(shortened[i18n.resolvedLanguage][weapon.type]) : t(weapon.type)
    }
    return (
        <Grid item xs='auto'>
            <Link
                to={`/meta/${category === MetaCategories.Ranked ? 'ranked/' : ''}${weaponImageName(weapon)}`}
                style={{ textDecoration: 'none' }}>
                <Stack alignItems='center' sx={{
                    m: '5px 8px',
                    p: '4px',
                    width: '180px',
                    borderRadius: '4px',
                    backgroundColor: 'rgb(0,0,0,0.2)',
                    boxShadow: '0 0 5px 0 rgb(0,0,0,0.5)',
                    transition: 'all 0.3s ease',
                    '&:hover': { backgroundColor: 'rgba(60,60,60,0.1)', boxShadow: '0 0 5px 1px rgba(255,255,255,0.3)' }
                }}>
                    {weaponImage(weapon)}
                    <Stack direction='row' alignItems='center' justifyContent='center' width='100%'>
                        <Typography m='0 8px' fontSize='1rem' fontWeight='normal' fontStyle='normal'
                            color='text.primary'>{weaponName(weapon)}</Typography>
                        <Typography m='0 8px' fontSize='1rem' fontWeight='normal' fontStyle='normal'
                            color='#999'>{shortWeaponType(weapon)}</Typography>
                    </Stack>
                </Stack>
            </Link>
        </Grid>
    )
}

function getChangeIcon(weapon) {
    return weapon.change === 'up' ? <ArrowDropUp sx={{ p: 0 }} /> :
        weapon.change === 'new' ? <Add sx={{ p: 0 }} /> :
            weapon.change === 'down' ? <ArrowDropDown sx={{ p: 0 }} /> : 0

}

function createWeapons(tier, category) {
    return tier.weapons
        .map(weapon =>
            weapon.endsWith('%') ? { name: weapon.replace('%', ''), change: 'down' } :
                weapon.endsWith('^') ? { name: weapon.replace('^', ''), change: 'up' } :
                    weapon.endsWith('*') ? { name: weapon.replace('*', ''), change: 'new' } :
                        { name: weapon }
        )
        .map(weapon => {
            return <Badge badgeContent={getChangeIcon(weapon)} 
                color={weapon.change === 'down' ? 'error' : weapon.change === 'new' ? 'info' : 'success'}
                sx={{ "& .MuiBadge-badge": { top: '10%', right: '10%', p: 0 } }}>
                <MetaWeaponItem weapon={allWeapons.find(w => w.name === weapon.name)} category={category} />
            </Badge>
        })
}

const MetaTierAccordion = ({ tier, category }) => {
    return (
        <Accordion elevation={10} defaultExpanded={tier.expanded ?? true} disableGutters sx={{
            borderRadius: '5px',
            backgroundColor: 'background.paper',
            boxShadow: '0px 0px 6px 1px #00000065',
            m: '5px 0',
            width: '100%'
        }}>
            <AccordionSummary
                sx={{ '& .MuiAccordionSummary-content': { m: '5px 0' } }}
                expandIcon={<ExpandMoreIcon sx={{ color: tier.color }} />}
                aria-controls="advanced-content"
                id="advanced-header"
            >
                <Typography fontSize='1.8rem' fontWeight='bold' color={tier.color}
                    sx={{ textShadow: '1px 1px 1px #000000' }}>
                    {tier.name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: '8px' }}>
                <Grid container justifyContent={{ xs: 'center', md: 'start' }}>
                    {createWeapons(tier, category)}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

const LightToggleButton = styled(ToggleButton)({
    '&.MuiToggleButton-root': {
        textTransform: 'none',
        fontSize: '1rem',
        color: '#aaa',
    },
    '&.MuiToggleButton-root.Mui-selected': {
        color: 'white',
    },
})

export const MetaCategories = {
    Ranked: 'ranked',
    Casual: 'casual'
}

function MetaPage({ category = MetaCategories.Casual }) {
    const { t } = useTranslation(Namespaces.meta)
    const isMobile = useIsMobileHook()
    const weaponName = useParams().metaWeapon
    const weapon = allWeapons.find(w => weaponImageName(w) === weaponName)
    const navigate = useNavigate()
    return <Stack m={isMobile ? '10px 0' : '20px'} alignItems='center'>
        <Stack sx={{ width: { xs: '100%', md: '70%' }, alignItems: 'center', alignSelf: 'center' }}>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems='center' justifyContent='center' width='100%'>
                <h1 style={{
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '1.6rem',
                    lineHeight: 1,
                    margin: '0 0 8px'
                }}>{t(category + 'Title')}</h1>
                <Stack display={category === MetaCategories.Ranked ? null : 'none'} direction='row'
                    alignItems='center' justifyContent='center'
                    sx={{ padding: '1px 0 0 5px', opacity: '80%' }}>
                    <Typography fontStyle='oblique' fontSize='0.9rem'
                        sx={{ color: 'text.primary', padding: '0 0 7px 10px' }}>
                        {t('collaboration')}
                    </Typography>
                    <a target='_blank' rel='noreferrer' href='https://xdi.gg'>
                        <img draggable={false} src={xdiLogo} height='28px' style={{ padding: '8px' }} alt='xdi.gg logo' />
                    </a>
                </Stack>
            </Stack>
            <h2 style={{
                textAlign: 'center',
                color: 'white',
                fontSize: isMobile ? '0.875rem' : '1rem',
                lineHeight: '1.25',
                fontWeight: 'normal',
                fontStyle: 'oblique',
                margin: '0 0 10px'
            }}>
                {t(category + 'Desc')}
            </h2>
        </Stack>
        <Stack alignItems='stretch' sx={{ width: { xs: '100%', md: '85%' }, alignItems: 'center', alignSelf: 'center' }}>
            <ToggleButtonGroup
                value={category}
                exclusive
                onChange={(e, category) => category === MetaCategories.Ranked ? navigate('/meta/ranked') : navigate('/meta')}
                aria-label="meta-category-select"
                sx={{ alignSelf: 'center', height: '30px' }}
            >
                <LightToggleButton value={MetaCategories.Casual} aria-label="left aligned">
                    {t(MetaCategories.Casual)}
                </LightToggleButton>
                <LightToggleButton value={MetaCategories.Ranked} aria-label="right aligned">
                    {t(MetaCategories.Ranked)}
                </LightToggleButton>
            </ToggleButtonGroup>
            <Typography color='white' fontSize='0.8rem' textAlign='center' m='5px 0'>
                {t(category + 'Toggle')}
            </Typography>
            {/*<Typography m='5px' p='5px' variant='smallHead' backgroundColor='rgb(17,123,197)' borderRadius='5px'*/}
            {/*            color='white' textAlign='center'*/}
            {/*            sx={{cursor: 'pointer'}} onClick={() => navigate('/updates')}>*/}
            {/*    Weapon stats are fully updated with the latest balance patch! Check out a breakdown of the*/}
            {/*    changes <u>here</u>.*/}
            {/*</Typography>*/}
            <MobileAd slot={MobileSlots.HORIZONTAL_1} nitroSlot={`meta-${category}-1`} />
            {ranking[category].flatMap((tier, i) => [
                <MetaTierAccordion key={tier.name} tier={tier} category={category} />,
                i < 2 ? <MobileAd key={`ad-${tier.name}`} nitroSlot={`meta-${category}-${i + 2}`} nitroSize={AdSizes.MobileLarge} /> : null
            ])}
        </Stack>
        <MetaLoadouts weapon={weapon} open={weapon != null} category={category}
            setOpen={() => category === MetaCategories.Ranked ? navigate('/meta/ranked') : navigate('/meta')} />
    </Stack>
}

export default MetaPage