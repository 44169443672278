import {useLocation} from "react-router-dom";
import {Stack, useMediaQuery} from "@mui/material";
import React, {useEffect, useState} from "react";
import AdsenseComponent from "./AdsenseComponent";
import NitroAd, {AdSizes} from "./NitroAds";

const adsenseSlots = {
    left: ['3088828307', '6383629579', '8874061540'],
    right: ['3822963730', '4247586670', '5816104627']
}

const DesktopMainBannerAds = () => {
    const location = useLocation();
    const builderPage = location.pathname === '/builder'
    const smallAds = useMediaQuery('(max-width: 1900px)') || builderPage
    const ads = useMediaQuery(`(min-width: ${builderPage ? '1750px' : '1500px'})`) && window.config?.ads

    const [currentBanners, setCurrentBanners] = useState(null)

    useEffect(() => {
        currentBanners == null ? setCurrentBanners(0) : setCurrentBanners((currentBanners + 1) % 3)
    }, [location])

    return ads ?
                [<Stack sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'fixed',
                    left: `calc(((100vw - ${builderPage ? '1300px' : '1100px'}) / 4) - ${smallAds ? '80px' : '150px'})`,
                    top: 'calc(max(50vh - 300px, 200px))',
                    height: '600px',
                    // minWidth: '160px',
                    // minHeight: '600px',
                    // top: 0, bottom: 0,
                    zIndex: 1400,
                    pointerEvents: 'none'
                }}>
                    {/*<div style={{minHeight: 'calc(50vh - 300px)'}}/>*/}
                    {window.config?.playwire ? <NitroAd key={`left-${location.key}`} adSlot='desktop-main-left' adSizes={smallAds ? AdSizes.DesktopSmallBanner : AdSizes.DesktopMainBanner}/> :
                        <AdsenseComponent adClient="ca-pub-9475011578827400" adSlot={adsenseSlots.left[currentBanners]}
                                          adFormat="auto" key={`left-${location.key}`}/>}
                </Stack>,
                <Stack sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'fixed',
                    right: `calc(((100vw - ${builderPage ? '1300px' : '1100px'}) / 4) -  ${smallAds ? '80px' : '150px'})`,
                    top: 'calc(max(50vh - 300px, 200px))',
                    height: '600px',
                    // minWidth: '160px',
                    // minHeight: '600px',
                    zIndex: 1400,
                    pointerEvents: 'none'
                }}>
                    {window.config?.playwire ? <NitroAd key={`right-${location.key}`} adSlot='desktop-main-right' adSizes={smallAds ? AdSizes.DesktopSmallBanner : AdSizes.DesktopMainBanner}/> :
                        <AdsenseComponent adClient="ca-pub-9475011578827400" adSlot={adsenseSlots.right[currentBanners]}
                                          adFormat="auto" key={`right-${location.key}`}/>}
                </Stack>]
        : null
}

export default DesktopMainBannerAds