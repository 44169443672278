import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";

const AdsenseComponent = ({adClient = 'ca-pub-9475011578827400', adSlot, adFormat, mobile = false, responsive, height, width}) => {
    const location = useLocation()

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({})
        } catch (e) {
            console.log(e)
        }
    }, [location])

    const desktopStyles = {display: 'block'}
    const mobileStyles = {display: 'inline-block', width: '360px', height: '90px'}
    const customStyles = {display: 'inline-block', width, height }
    return window.config?.adsense ? <ins className="adsbygoogle"
             style={height != null & width != null ? customStyles : mobile ? mobileStyles : desktopStyles}
             data-ad-client={adClient}
             data-ad-slot={adSlot}
             data-ad-format={adFormat}
             data-full-width-responsive={responsive == null ? (!mobile).toString() : responsive.toString()}>
    </ins> : null
}

export default AdsenseComponent;