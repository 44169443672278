import { useEffect } from "react"
import { useIsMobileHook } from "../../utils"

const KofiButton = () => {
    const isMobile = useIsMobileHook()

    useEffect(() => {
        try {
            if (!isMobile) {
                window.kofiWidgetOverlay.draw('xdloadout', {
                    'type': 'floating-chat',
                    'floating-chat.donateButton.text': 'Support XDL',
                    'floating-chat.donateButton.background-color': '#323842',
                    'floating-chat.donateButton.text-color': '#fff',
                })
            } else {
                document.querySelector('[id^="kofi-widget"]')?.remove();
            }
        } catch (e) {console.log(e)}
    }, [isMobile])


    return null
}

export default KofiButton