import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export const AdSizes = {
    MobileBanner: [
        [320, 100],
        [320, 50],
    ],
    MobileLarge: [
        [320, 100],
        [300, 250],
        [320, 50],
    ],
    MobileAnchor: [
        [320, 100],
        [320, 50],
    ],
    DesktopMainBanner: [
        [160, 600],
        [300, 600],
        [300, 250],
    ],
    DesktopSmallBanner: [
        [160, 600]
    ],
    DesktopHorizontalBanner: [
        [728, 90],
        [970, 90]
    ],
    NitroAnchor: 'NITRO_ANCHOR'
}

const NitroAd = ({adSlot, adSizes}) => {
    const location = useLocation()
    const slotName = `nitro-${adSlot}`

    return window.config?.playwire ? <div style={{pointerEvents: 'auto', width: adSizes[0][0], height: adSizes[0][1], backgroundColor: "white", color: "black"}}>
        <div id={slotName}>{adSizes.reduce((prev, next) => prev + `${next[0]}x${next[1]}\n`, "")}</div>
        </div> : null

}

export default NitroAd

